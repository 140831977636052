import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Pagination,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useLazyQuery, useQuery } from "@apollo/client";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { USERS } from "../../gqls/user";

const TAKE = 30;

export const UsersPage = () => {
  const navigate = useNavigate();

  const [isLandlord, setIsLandlord] = useState(false);
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [downloadExcelLoading, setDownloadExcelLoading] = useState(false);

  const userVariables = {
    search: filterSearch,
    where: { isLandlord },
  };

  const [getUsers] = useLazyQuery(USERS);

  const { data, loading } = useQuery(USERS, {
    variables: {
      skip: (activePage - 1) * TAKE,
      take: TAKE,
      ...userVariables,
    },
    fetchPolicy: "network-only",
  });

  const users = data?.users || [];
  const usersCount = data?.usersCount || 0;

  const pagesCount = Math.ceil(usersCount / TAKE);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setFilterSearch(search ? search : "");
    }, 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [search]);

  const handleDownloadUsers = () => {
    setDownloadExcelLoading(true);
    getUsers({ variables: userVariables })
      .then((res) => {
        const users = res?.data?.users || [];
        const rows = [
          [
            "ID",
            "Дата регистрации",
            "Номер телефона",
            "Имя",
            "Email",
            "Дата рождения",
            "Город",
          ],
        ];
        for (let user of users) {
          rows.push([
            user.id,
            dayjs(user.createdAt).format("DD.MM.YYYY HH:mm"),
            user.phone,
            user.name,
            user.email,
            user.birthday,
            user.city,
          ]);
        }
        let csvContent =
          "data:text/csv;charset=utf-8," +
          rows.map((e) => e.join(",")).join("\n");
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `Пользователи-${dayjs().format("DDMMYYYYHHmm")}.csv`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setDownloadExcelLoading(false));
  };

  const rows = users.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <Link to={`/users/${item.id}`}>{item.id.slice(0, 6)}...</Link>
        </Table.Td>
        <Table.Td>{item.phone}</Table.Td>
        <Table.Td>{item.name}</Table.Td>
        <Table.Td>{item.email}</Table.Td>
        <Table.Td>{item.city}</Table.Td>
        <Table.Td>{dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Top>
        <Text>Пользователи ({usersCount})</Text>
        <Group className="buttons">
          <Button
            onClick={() => navigate("/users/create")}
            variant="outline"
            size="xs"
          >
            Добавить пользователя
          </Button>
          <Button
            onClick={handleDownloadUsers}
            variant="outline"
            size="xs"
            loading={downloadExcelLoading}
          >
            Скачать Excel
          </Button>
        </Group>
      </Top>
      <Group mt={10} mb={12} spacing="sm">
        <TextInput
          size="xs"
          placeholder="Введите номер или телефон"
          label="Поиск"
          w={200}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Group>
      <Group mb={12}>
        <Checkbox
          checked={isLandlord}
          onChange={() => setIsLandlord((prev) => !prev)}
          label="Арендодатели"
          size="xs"
        />
      </Group>
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ID</Table.Th>
              <Table.Th>Телефон</Table.Th>
              <Table.Th>Имя</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Город</Table.Th>
              <Table.Th>Дата регистрации</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination
        onChange={(v) => setActivePage(v)}
        value={activePage}
        size="sm"
        mt={16}
        total={pagesCount}
      />
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1000px;
    width: 100%;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 550px) {
    flex-direction: column;

    .buttons {
      margin-top: 12px;
    }
  }
`;
