import { gql } from '@apollo/client';

export const OBJECT_FRAGMENT = gql`
  fragment ObjectReturn on Object {
    id
    createdAt
    updatedAt
    type
    title
    description
    square
    floor
    guestsCount
    roomsCount
    sleepCount
    images
    price
    priceDay
    priceNight
    dayPrices {
      id
      day
      price
      priceDay
      priceNight
    }
    specialDayPrices {
      id
      day
      price
      priceDay
      priceNight
    }
    sales {
      id
      amount
      days
    }
    checkinTime
    checkoutTime
    dayCheckinTime
    dayCheckoutTime
    nightCheckinTime
    nightCheckoutTime
    startWorkDate
    unavailableDates {
      id
      fromDate
      toDate
    }
    address
    city
    country
    longitude
    latitude
    status
    statusComment
    user {
      id
      name
      avatar
      phone
    }
    rating
    reviewsCount
    number
  }
`;

export const OBJECT = gql`
  ${OBJECT_FRAGMENT}
  query OBJECT($where: ObjectWhereInput!) {
    object(where: $where) {
      ...ObjectReturn
    }
  }
`;

export const OBJECTS = gql`
  ${OBJECT_FRAGMENT}
  query OBJECTS($where: ObjectsWhereInput, $skip: Int, $take: Int) {
    objects(where: $where, skip: $skip, take: $take) {
      ...ObjectReturn
    }
    objectsCount(where: $where)
  }
`;

export const ADMIN_UPDATE_OBJECT = gql`
  ${OBJECT_FRAGMENT}
  mutation ADMIN_UPDATE_OBJECT($where: ObjectWhereInput!, $data: AdminUpdateObjectInput!) {
    adminUpdateObject(where: $where, data: $data) {
      ...ObjectReturn
    }
  }
`;

export const ADMIN_CREATE_OBJECT = gql`
  ${OBJECT_FRAGMENT}
  mutation ADMIN_CREATE_OBJECT($data: AdminCreateObjectInput!) {
    adminCreateObject(data: $data) {
      ...ObjectReturn
    }
  }
`;
