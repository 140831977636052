import { gql } from "@apollo/client";

export const ADMIN_APPLICATIONS_FRAGMENT = gql`
  fragment ApplicationReturn on Application {
    id
    createdAt
    updatedAt
    user {
      id
      name
    }
    object {
      id
      title
      price
      user {
        id
        name
      }
      dayPrices {
        id
        day
        price
      }
      sales {
        id
        amount
        days
      }
    }
    fromDate
    toDate
    adultsCount
    childsCount
    status
    number
    price
  }
`;

export const ADMIN_APPLICATIONS = gql`
  ${ADMIN_APPLICATIONS_FRAGMENT}
  query ADMIN_APPLICATIONS(
    $where: AdminApplicationsWhereInput
    $skip: Int
    $take: Int
  ) {
    adminApplications(where: $where, skip: $skip, take: $take) {
      ...ApplicationReturn
    }
    adminApplicationsCount(where: $where)
  }
`;

export const ADMIN_APPLICATION = gql`
  ${ADMIN_APPLICATIONS_FRAGMENT}
  query ADMIN_APPLICATION($where: ApplicationWhereInput!) {
    adminApplication(where: $where) {
      ...ApplicationReturn
    }
  }
`;
