import {
  ActionIcon,
  Button,
  Flex,
  Select,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import React from "react";
import { IconX } from "@tabler/icons-react";

export const DayPricesInput = ({ dayPrices, setDayPrices }) => {
  const getDefaultDay = () => {
    const lastDayPrice = dayPrices[dayPrices.length - 1];
    if (lastDayPrice) {
      if (lastDayPrice.day === "monday") return "tuesday";
      if (lastDayPrice.day === "tuesday") return "wednesday";
      if (lastDayPrice.day === "wednesday") return "thursday";
      if (lastDayPrice.day === "thursday") return "friday";
      if (lastDayPrice.day === "friday") return "saturday";
      if (lastDayPrice.day === "saturday") return "sunday";
      return "";
    } else {
      return "monday";
    }
  };

  return (
    <>
      <Text size="sm" fw={500}>
        Цена по дням
      </Text>
      {dayPrices.length ? (
        <>
          {dayPrices.map((item) => (
            <React.Fragment key={item.key}>
              <Space h={8} />
              <Flex justify="space-between" align="center">
                <Select
                  flex={1}
                  label="День недели"
                  value={item.day}
                  size="xs"
                  onChange={(v) =>
                    setDayPrices((prev) => {
                      return prev.map((it) => {
                        if (it.key === item.key) {
                          return { ...it, day: v };
                        }
                        return it;
                      });
                    })
                  }
                  data={[
                    {
                      value: "monday",
                      label: "Понедельник",
                      disabled: !!dayPrices.find((it) => it.day === "monday"),
                    },
                    {
                      value: "tuesday",
                      label: "Вторник",
                      disabled: !!dayPrices.find((it) => it.day === "tuesday"),
                    },
                    {
                      value: "wednesday",
                      label: "Среда",
                      disabled: !!dayPrices.find(
                        (it) => it.day === "wednesday"
                      ),
                    },
                    {
                      value: "thursday",
                      label: "Четверг",
                      disabled: !!dayPrices.find((it) => it.day === "thursday"),
                    },
                    {
                      value: "friday",
                      label: "Пятница",
                      disabled: !!dayPrices.find((it) => it.day === "friday"),
                    },
                    {
                      value: "saturday",
                      label: "Суббота",
                      disabled: !!dayPrices.find((it) => it.day === "saturday"),
                    },
                    {
                      value: "sunday",
                      label: "Воскресенье",
                      disabled: !!dayPrices.find((it) => it.day === "sunday"),
                    },
                  ]}
                />
                <Space w={16} />
                <TextInput
                  label="Цена"
                  size="xs"
                  value={item.price}
                  type="number"
                  onChange={(e) =>
                    setDayPrices((prev) => {
                      return prev.map((it) => {
                        if (it.key === item.key) {
                          return { ...it, price: parseFloat(e.target.value) };
                        }
                        return it;
                      });
                    })
                  }
                />
                <Space w={16} />
                <div>
                  <Space h={28} />
                  <ActionIcon
                    color="red"
                    size="sm"
                    onClick={() => {
                      setDayPrices((prev) => {
                        return prev.filter((it) => it.day !== item.day);
                      });
                    }}
                  >
                    <IconX size={16} />
                  </ActionIcon>
                </div>
              </Flex>
            </React.Fragment>
          ))}
        </>
      ) : null}
      <Space h={12} />
      <Button
        size="xs"
        variant="outline"
        onClick={() =>
          setDayPrices((prev) => {
            const day = getDefaultDay();
            if (!day) return prev;
            return [...prev, { day, price: 0, key: prev.length }];
          })
        }
      >
        Добавить день
      </Button>
    </>
  );
};
