import { createBrowserRouter } from "react-router-dom";

import { HomePage } from "./pages/home";
import { LoginPage } from "./pages/login";
import { withLayout } from "./components/layout";
import { ObjectsPage } from "./pages/objects";
import { ObjectPage } from "./pages/objects/object";
import { UsersPage } from "./pages/users";
import { UserPage } from "./pages/users/user";
import { CreateUserPage } from "./pages/users/create";
import { CreateObjectPage } from "./pages/objects/create";
import { UpdateObjectPage } from "./pages/objects/update";
import { ApplicationsPage } from "./pages/applications";
import { ConfigsPage } from "./pages/configs";
import { PushNotificationsPage } from "./pages/push-notifications";

export const router = createBrowserRouter([
  {
    path: "/",
    element: withLayout(HomePage),
  },
  {
    path: "/objects",
    element: withLayout(ObjectsPage),
  },
  {
    path: "/objects/create",
    element: withLayout(CreateObjectPage),
  },
  {
    path: "/objects/:id",
    element: withLayout(ObjectPage),
  },
  {
    path: "/objects/:id/update",
    element: withLayout(UpdateObjectPage),
  },
  {
    path: "/users",
    element: withLayout(UsersPage),
  },
  {
    path: "/users/create",
    element: withLayout(CreateUserPage),
  },
  {
    path: "/users/:id",
    element: withLayout(UserPage),
  },
  {
    path: "/applications",
    element: withLayout(ApplicationsPage),
  },
  {
    path: "/configs",
    element: withLayout(ConfigsPage),
  },
  {
    path: "/push-notifications",
    element: withLayout(PushNotificationsPage),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]);
