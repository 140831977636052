import {
  ActionIcon,
  Button,
  Flex,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import React from "react";
import { IconX } from "@tabler/icons-react";

export const SalesInput = ({ sales, setSales }) => {
  return (
    <>
      <Text size="sm" fw={500}>
        Скидки
      </Text>
      {sales.length ? (
        <>
          {sales.map((item) => (
            <React.Fragment key={item.key}>
              <Space h={8} />
              <Flex justify="space-between" align="center">
                <TextInput
                  label="Количество дней"
                  size="xs"
                  value={item.days}
                  type="number"
                  onChange={(e) =>
                    setSales((prev) => {
                      return prev.map((it) => {
                        if (it.key === item.key) {
                          return { ...it, days: parseInt(e.target.value) };
                        }
                        return it;
                      });
                    })
                  }
                  flex={1}
                />
                <Space w={16} />
                <TextInput
                  label="Цена"
                  size="xs"
                  value={item.amount}
                  type="number"
                  onChange={(e) =>
                    setSales((prev) => {
                      return prev.map((it) => {
                        if (it.key === item.key) {
                          return { ...it, amount: parseFloat(e.target.value) };
                        }
                        return it;
                      });
                    })
                  }
                />
                <Space w={16} />
                <div>
                  <Space h={28} />
                  <ActionIcon
                    color="red"
                    size="sm"
                    onClick={() => {
                      setSales((prev) => {
                        return prev.filter((it) => it.key !== item.key);
                      });
                    }}
                  >
                    <IconX size={16} />
                  </ActionIcon>
                </div>
              </Flex>
            </React.Fragment>
          ))}
        </>
      ) : null}
      <Space h={12} />
      <Button
        size="xs"
        variant="outline"
        onClick={() =>
          setSales((prev) => {
            return [...prev, { days: 0, amount: 0, key: prev.length }];
          })
        }
      >
        Добавить скидку
      </Button>
    </>
  );
};
