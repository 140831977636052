import dayjs from 'dayjs';

export const calculatePrice = ({
  price = 0,
  priceDay = 0,
  priceNight = 0,
  fromDate,
  toDate,
  dayPrices = [],
  sales = [],
  specialDayPrices = [],
  period = 'full-day',
}) => {
  let startDate = fromDate ? dayjs(fromDate).startOf('day') : undefined;
  let endDate = toDate ? dayjs(toDate).startOf('day') : undefined;
  if (period !== 'full-day') {
    startDate = dayjs(fromDate).startOf('day');
    endDate = startDate.add(1, 'days');
  }
  let days = startDate && endDate ? Math.abs(startDate.diff(endDate, 'days')) : 0;
  if (days <= 1) days = 1;
  let objectPrice = price;
  if (period === 'day') objectPrice = priceDay;
  if (period === 'night') objectPrice = priceNight;
  const fullPrice = objectPrice * days;
  let salePrice = 0;
  if (startDate && endDate) {
    for (let d = startDate; d.isBefore(endDate); d = d.add(1, 'days')) {
      const weekDay = getWeekDay(d.day());
      const specialDay = d.format('YYYY-MM-DD');
      const weekDayPrice = dayPrices.find((item) => item.day === weekDay);
      const specialDayPrice = specialDayPrices.find((item) => item.day === specialDay);
      let dayPrice = objectPrice;
      if (weekDayPrice) {
        if (weekDayPrice.price) dayPrice = weekDayPrice.price;
        if (period === 'day' && weekDayPrice.priceDay) dayPrice = weekDayPrice.priceDay;
        if (period === 'night' && weekDayPrice.priceNight) dayPrice = weekDayPrice.priceNight;
      }
      if (specialDayPrice) {
        if (specialDayPrice.price) dayPrice = specialDayPrice.price;
        if (period === 'day' && specialDayPrice.priceDay) dayPrice = specialDayPrice.priceDay;
        if (period === 'night' && specialDayPrice.priceNight) dayPrice = specialDayPrice.priceNight;
      }
      salePrice = salePrice + dayPrice;
    }
  }
  if (sales.length) {
    const existSaleDays = sales.find((item) => item.days <= days);
    if (existSaleDays) salePrice = salePrice - existSaleDays.amount;
  }
  if (salePrice <= 0) salePrice = 0;
  return { fullPrice, salePrice, days };
};

const getWeekDay = (index) => {
  if (index === 0) return 'sunday';
  if (index === 1) return 'monday';
  if (index === 2) return 'tuesday';
  if (index === 3) return 'wednesday';
  if (index === 4) return 'thursday';
  if (index === 5) return 'friday';
  if (index === 6) return 'saturday';
  return null;
};
