import { gql } from "@apollo/client";

const USER_FRAGMENT = gql`
  fragment UserReturn on User {
    id
    createdAt
    updatedAt
    phone
    name
    email
    birthday
    avatar
    city
  }
`;

export const USERS = gql`
  ${USER_FRAGMENT}
  query USERS(
    $skip: Int
    $take: Int
    $search: String
    $where: UsersWhereInput
  ) {
    users(skip: $skip, take: $take, search: $search, where: $where) {
      ...UserReturn
    }
    usersCount(search: $search, where: $where)
  }
`;

export const ADMIN_CREATE_USER = gql`
  ${USER_FRAGMENT}
  mutation ADMIN_CREATE_USER($data: AdminCreateUserInput!) {
    adminCreateUser(data: $data) {
      ...UserReturn
    }
  }
`;

export const SINGLE_USER = gql`
  ${USER_FRAGMENT}
  query SINGLE_USER($where: UserWhereInput!) {
    singleUser(where: $where) {
      ...UserReturn
    }
  }
`;
