import React, { useState } from 'react';
import { Badge, Button, Group, LoadingOverlay, Pagination, Select, Table, Text } from '@mantine/core';
import styled from 'styled-components';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DateInput } from '@mantine/dates';

import { ADMIN_APPLICATIONS } from '../../gqls/applications';
import { calculatePrice } from '../../utils';

const TAKE = 30;

export const ApplicationsPage = () => {
  const [status, setStatus] = useState('');
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [activePage, setActivePage] = useState(1);
  const [downloadExcelLoading, setDownloadExcelLoading] = useState(false);

  const [getApplicationsForDownload] = useLazyQuery(ADMIN_APPLICATIONS);

  const { data, loading } = useQuery(ADMIN_APPLICATIONS, {
    variables: {
      skip: (activePage - 1) * TAKE,
      take: TAKE,
      where: {
        status,
        fromDate,
        toDate,
      },
    },
  });

  const applications = data?.adminApplications || [];
  const applicationsCount = data?.adminApplicationsCount || 0;

  const pagesCount = Math.ceil(applicationsCount / TAKE);

  const handleDownloadApplications = () => {
    setDownloadExcelLoading(true);
    getApplicationsForDownload({
      variables: {
        where: {
          status,
          fromDate,
          toDate,
        },
      },
    })
      .then((res) => {
        const applications = res?.data?.adminApplications || [];
        const rows = [
          ['ID', 'Номер', 'Объект', 'Арендодатель', 'Арендующий', 'Даты', 'Дата создания', 'Сумма', 'Статус'],
        ];
        for (let item of applications) {
          const status = getApplicationStatus(item.status);
          const price = calculatePrice({
            price: item.object.price,
            priceDay: item.object.priceDay,
            priceNight: item.object.priceNight,
            fromDate: item.fromDate,
            toDate: item.toDate,
            dayPrices: item.object.dayPrices,
            specialDayPrices: item.object.specialDayPrices,
            sales: item.object.sales,
            period: 'full-day',
          });
          rows.push([
            item.id,
            item.number,
            `https://admin.arendom.pro/objects/${item.object.id}`,
            `https://admin.arendom.pro/users/${item.object.user.id}`,
            `https://admin.arendom.pro/users/${item.user.id}`,
            `${dayjs(item.fromDate).format('DD.MM.YYYY')} - ${dayjs(item.toDate).format('DD.MM.YYYY')}`,
            dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
            `${item.price ? item.price : price.salePrice ? price.salePrice : price.fullPrice} ₽`,
            status ? status.label : '',
          ]);
        }
        let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Заявки-${dayjs().format('DDMMYYYYHHmm')}.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setDownloadExcelLoading(false));
  };

  const rows = applications.map((item) => {
    const status = getApplicationStatus(item.status);
    const price = calculatePrice({
      price: item.object.price,
      priceDay: item.object.priceDay,
      priceNight: item.object.priceNight,
      fromDate: item.fromDate,
      toDate: item.toDate,
      dayPrices: item.object.dayPrices,
      specialDayPrices: item.object.specialDayPrices,
      sales: item.object.sales,
      period: 'full-day',
    });
    return (
      <Table.Tr key={item.id}>
        <Table.Td>{item.number}</Table.Td>
        <Table.Td>
          <Link to={`/objects/${item.object.id}`} className="title">
            {item.object.title}
          </Link>
        </Table.Td>
        <Table.Td>
          <Link to={`/users/${item.object.user.id}`} className="username">
            {item.object.user.name}
          </Link>
        </Table.Td>
        <Table.Td>
          <Link to={`/users/${item.user.id}`} className="username">
            {item.user.name}
          </Link>
        </Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap' }}>
          {dayjs(item.fromDate).format('DD.MM.YYYY')} - {dayjs(item.toDate).format('DD.MM.YYYY')}
        </Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap' }}>{dayjs(item.createdAt).format('DD.MM.YYYY HH:mm')}</Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap' }}>
          {item.price ? item.price : price.salePrice ? price.salePrice : price.fullPrice} ₽
        </Table.Td>
        <Table.Td>{status ? <Badge color={status.color}>{status.label}</Badge> : null}</Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Top>
        <Text>Заявки на бронь ({applicationsCount})</Text>
        <Group className="buttons">
          <Button onClick={handleDownloadApplications} variant="outline" size="xs" loading={downloadExcelLoading}>
            Скачать Excel
          </Button>
        </Group>
      </Top>
      <Group mt={10} mb={12} spacing="sm">
        <Select
          size="xs"
          label="Статус"
          clearable
          data={[
            { value: 'new', label: 'Ожидает подтверждения' },
            { value: 'confirmed', label: 'Бронь одобрена' },
            { value: 'cancelled', label: 'Бронь отменена' },
            { value: 'rejected', label: 'Бронь отклонена' },
            { value: 'finished', label: 'Аренда завершена' },
            { value: 'accepted-other', label: 'Выбран другой арендатор' },
          ]}
          placeholder="Выберите статус"
          value={status}
          onChange={(v) => setStatus(v)}
        />
        <DateInput label="Дата от" size="xs" value={fromDate} clearable onChange={(v) => setFromDate(v)} />
        <DateInput label="Дата до" size="xs" value={toDate} clearable onChange={(v) => setToDate(v)} />
      </Group>
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>№</Table.Th>
              <Table.Th>Объект</Table.Th>
              <Table.Th>Арендодатель</Table.Th>
              <Table.Th>Арендующий</Table.Th>
              <Table.Th>Даты</Table.Th>
              <Table.Th>Дата создания</Table.Th>
              <Table.Th>Сумма</Table.Th>
              <Table.Th>Статус</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination onChange={(v) => setActivePage(v)} value={activePage} size="sm" mt={16} total={pagesCount} />
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }

    .title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .username {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 550px) {
    flex-direction: column;

    .buttons {
      margin-top: 12px;
    }
  }
`;

export const getApplicationStatus = (status) => {
  if (status === 'new') {
    return {
      label: 'Ожидает подтверждения',
      color: 'blue',
    };
  }
  if (status === 'confirmed') {
    return {
      label: 'Бронь одобрена',
      color: 'green',
    };
  }
  if (status === 'cancelled') {
    return {
      label: 'Бронь отменена',
      color: 'gray',
    };
  }
  if (status === 'rejected') {
    return {
      label: 'Бронь отклонена',
      color: 'red',
    };
  }
  if (status === 'finished') {
    return {
      label: 'Аренда завершена',
      color: 'violet',
    };
  }
  if (status === 'accepted-other') {
    return {
      label: 'Выбран другой арендатор',
      color: 'red',
    };
  }
  return null;
};
