import { useState } from "react";
import { Button, Group, Modal, Space, Text, TextInput } from "@mantine/core";

import { NameValue } from "../components/name-value";
import { useAdmin } from "../hooks/use-admin";
import { useMutation } from "@apollo/client";
import { UPDATE_ADMIN_PASSWORD } from "../gqls/admin";

export const HomePage = () => {
  const { admin } = useAdmin();

  const [passwordEditVisible, setPasswordEditVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [updateAdminPassword, { loading }] = useMutation(
    UPDATE_ADMIN_PASSWORD,
    {
      onCompleted: () => {
        alert("Пароль изменен");
        setPasswordEditVisible(false);
      },
      onError: (err) => {
        console.error(err);
        if (err.message === "password-error") {
          alert("Неправильный текущий пароль");
        } else {
          alert("Произошла ошибка");
        }
      },
    }
  );

  const handleChangePassword = () => {
    if (!currentPassword || !newPassword || !repeatPassword) return;
    if (newPassword !== repeatPassword) {
      alert("Пароли не совпадают");
      return;
    }
    updateAdminPassword({
      variables: {
        data: {
          currentPassword,
          newPassword,
        },
      },
    });
  };

  return (
    <>
      <Text>Профиль</Text>
      <Space h="12px" />
      <NameValue name="Email" value={admin.email} isLast />
      <Space h="16px" />
      <Button onClick={() => setPasswordEditVisible(true)}>
        Изменить пароль
      </Button>
      <Modal
        opened={passwordEditVisible}
        onClose={() => setPasswordEditVisible(false)}
        title="Изменить пароль"
      >
        <TextInput
          label="Текущий пароль"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          mb={12}
          type="password"
        />
        <TextInput
          label="Новый пароль"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          mb={12}
          type="password"
        />
        <TextInput
          label="Повторите новый пароль"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          mb={16}
          type="password"
        />
        <Group justify="flex-end">
          <Button
            onClick={() => setPasswordEditVisible(false)}
            variant="outline"
          >
            Отменить
          </Button>
          <Button loading={loading} onClick={handleChangePassword}>
            Изменить
          </Button>
        </Group>
      </Modal>
    </>
  );
};
