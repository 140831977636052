import "dayjs/locale/ru";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Notifications } from "@mantine/notifications";
import { DatesProvider } from "@mantine/dates";
import dayjs from "dayjs";

import { apolloClient } from "./utils/apollo";
import { router } from "./router";
import { GlobalStyles } from "./components/global-styles";

dayjs.locale("ru");

const App = () => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <DatesProvider
        settings={{ locale: "ru", firstDayOfWeek: 0, weekendDays: [0] }}
      >
        <ApolloProvider client={apolloClient}>
          <RouterProvider router={router} />
          <GlobalStyles />
          <Notifications />
        </ApolloProvider>
      </DatesProvider>
    </MantineProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
