import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  FileInput,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  TagsInput,
  Text,
  Textarea,
} from "@mantine/core";
import dayjs from "dayjs";
import styled from "styled-components";
import Papa from "papaparse";

import {
  CREATE_PUSH_NOTIFICATION,
  PUSH_NOTIFICATIONS,
} from "../../gqls/pushNotification";
import { notifications } from "@mantine/notifications";

const TAKE = 30;

export const PushNotificationsPage = () => {
  const [activePage, setActivePage] = useState(1);
  const [text, setText] = useState("");
  const [type, setType] = useState("");
  const [phones, setPhones] = useState([]);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);

  const { data, loading, refetch } = useQuery(PUSH_NOTIFICATIONS, {
    variables: {
      skip: (activePage - 1) * TAKE,
      take: TAKE,
    },
    fetchPolicy: "network-only",
  });

  const [createPushNotification, { loading: createLoading }] = useMutation(
    CREATE_PUSH_NOTIFICATION,
    {
      onCompleted: () => {
        refetch();
        setModalCreateVisible(false);
        notifications.show({
          color: "green",
          title: "Успешно",
          message: "Push уведомление отправлено",
        });
      },
      onError: (err) => {
        console.error(err);
        if (err.message === "not-users") {
          notifications.show({
            color: "red",
            title: "Ошибка",
            message: "Нет пользователей",
          });
        } else {
          notifications.show({
            color: "red",
            title: "Ошибка",
            message: "Произошла ошибка, повторите еще раз",
          });
        }
      },
    }
  );

  const pushNotifications = data?.pushNotifications || [];
  const pushNotificationsCount = data?.pushNotificationsCount || 0;

  const pagesCount = Math.ceil(pushNotificationsCount / TAKE);

  const rows = pushNotifications.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>{item.title}</Table.Td>
        <Table.Td>{getPushTypeName(item.type)}</Table.Td>
        <Table.Td style={{ whiteSpace: "nowrap" }}>
          {dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}
        </Table.Td>
      </Table.Tr>
    );
  });

  const handleSendPush = () => {
    createPushNotification({
      variables: { data: { title: text, type, phones } },
    });
  };

  return (
    <>
      <Flex justify="space-between" mb={7}>
        <Text>Push уведомления</Text>
        <Button
          onClick={() => setModalCreateVisible(true)}
          variant="outline"
          size="xs"
        >
          Отправить Push
        </Button>
      </Flex>
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Текст</Table.Th>
              <Table.Th>Тип</Table.Th>
              <Table.Th>Дата отправки</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination
        onChange={(v) => setActivePage(v)}
        value={activePage}
        size="sm"
        mt={16}
        total={pagesCount}
      />
      <Modal
        opened={modalCreateVisible}
        onClose={() => setModalCreateVisible(false)}
        title="Отправить Push уведомление"
      >
        <Select
          data={[
            { value: "all", label: "Всем" },
            { value: "landlord", label: "Арендодателям" },
            { value: "user", label: "Арендаторам" },
            { value: "phone", label: "По номеру телефона" },
          ]}
          label="Тип"
          value={type}
          onChange={(v) => setType(v)}
        />
        <Space h={12} />
        <Textarea
          label="Текст"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        {type === "phone" ? (
          <>
            <Space h={12} />
            <TagsInput
              label="Номера телефонов"
              placeholder="+79991112233"
              data={[]}
              value={phones}
              onChange={(v) => {
                const existInvalid = v.find(
                  (item) => item.length !== 12 || item[0] !== "+"
                );
                if (existInvalid) return;
                setPhones(v);
              }}
            />
            <Space h={6} />
            <FileInput
              placeholder="Выберите файл с номерами телефонов"
              onChange={(file) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                  const csv = Papa.parse(e.target.result, { header: true });
                  const parsedData = csv?.data;
                  const phones = [];
                  for (let item of parsedData) {
                    if (item["Номер телефона"]) {
                      phones.push(item["Номер телефона"]);
                    }
                  }
                  setPhones(phones);
                };
                reader.readAsText(file);
              }}
            />
          </>
        ) : null}
        <Space h={20} />
        <Group justify="flex-end">
          <Button
            variant="outline"
            onClick={() => setModalCreateVisible(false)}
          >
            Отмена
          </Button>
          <Button
            loading={createLoading}
            onClick={handleSendPush}
            disabled={
              !type || !text || (type === "phone" ? !phones.length : false)
            }
          >
            Отправить
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export const getPushTypeName = (type) => {
  if (type === "all") return "Всем";
  if (type === "landlord") return "Арендодателям";
  if (type === "user") return "Арендаторам";
  if (type === "phone") return "По номеру телефона";
  return "";
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
