import { useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Badge,
  Button,
  Center,
  Flex,
  Group,
  Loader,
  Modal,
  Select,
  Space,
  Text,
  Textarea,
} from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import React, { useState } from 'react';
import { notifications } from '@mantine/notifications';

import { ADMIN_UPDATE_OBJECT, OBJECT } from '../../gqls/object';
import { NameValue } from '../../components/name-value';
import { getObjectStatus, getObjectType } from '.';

export const ObjectPage = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [editStatusVisible, setEditStatusVisible] = useState(false);

  const { data, loading } = useQuery(OBJECT, {
    variables: { where: { id: params.id } },
  });

  const object = data?.object;

  const status = getObjectStatus(object?.status);

  return (
    <>
      <Text>Объект{object ? ` ${object.id}` : ''}</Text>
      {loading ? (
        <Center pt="20px" pb="20px">
          <Loader size="sm" />
        </Center>
      ) : object ? (
        <>
          <Space h="12px" />
          <Group>
            {object.images.map((item) => (
              <Img key={item} src={`/uploads/${item}`} />
            ))}
          </Group>
          <Space h="16px" />
          <NameValue name="Идентификатор" value={object.number} />
          <NameValue name="Дата создания" value={dayjs(object.createdAt).format('DD.MM.YYYY HH:mm')} />
          <NameValue name="Тип" value={getObjectType(object.type)} />
          <NameValue name="Заголовок" value={object.title} />
          <NameValue name="Описание" value={object.description} column />
          <NameValue name="Площадь" value={object.square ? object.square + ' м2' : ''} />
          <NameValue name="Количество гостей" value={object.guestsCount} />
          <NameValue name="Количество комнат" value={object.roomsCount} />
          <NameValue name="Количество спальных мест" value={object.sleepCount} />
          <NameValue name="Цена" value={object.price + ' ₽'} valueColor="#4B5CFA" />
          {object.priceDay ? (
            <NameValue name="Цена (день)" value={object.priceDay + ' ₽'} valueColor="#4B5CFA" />
          ) : null}
          {object.priceNight ? (
            <NameValue name="Цена (ночь)" value={object.priceNight + ' ₽'} valueColor="#4B5CFA" />
          ) : null}
          {object.dayPrices?.length
            ? object.dayPrices.map((item) => (
                <React.Fragment key={item.id}>
                  <NameValue name={`Цена на ${getDay(item.day)}`} value="" />
                  <Flex>
                    <Space w={30} />
                    <NameValue
                      name="Сутки"
                      fontSize="xs"
                      value={item.price + ' ₽'}
                      valueColor="#4B5CFA"
                      nameWidth={170}
                    />
                  </Flex>
                  {item.priceDay ? (
                    <Flex>
                      <Space w={30} />
                      <NameValue
                        name="День"
                        fontSize="xs"
                        value={item.priceDay + ' ₽'}
                        valueColor="#4B5CFA"
                        nameWidth={170}
                      />
                    </Flex>
                  ) : null}
                  {item.priceNight ? (
                    <Flex>
                      <Space w={30} />
                      <NameValue
                        name="Ночь"
                        fontSize="xs"
                        value={item.priceNight + ' ₽'}
                        valueColor="#4B5CFA"
                        nameWidth={170}
                      />
                    </Flex>
                  ) : null}
                </React.Fragment>
              ))
            : null}
          {object.specialDayPrices?.length
            ? object.specialDayPrices.map((item) => (
                <React.Fragment key={item.id}>
                  <NameValue name={`Цена на ${dayjs(item.day, 'YYYY-MM-DD').format('DD.MM.YYYY')}`} />
                  <Flex>
                    <Space w={30} />
                    <NameValue
                      name="Сутки"
                      fontSize="xs"
                      value={item.price + ' ₽'}
                      valueColor="#4B5CFA"
                      nameWidth={170}
                    />
                  </Flex>
                  {item.priceDay ? (
                    <Flex>
                      <Space w={30} />
                      <NameValue
                        name="День"
                        fontSize="xs"
                        value={item.priceDay + ' ₽'}
                        valueColor="#4B5CFA"
                        nameWidth={170}
                      />
                    </Flex>
                  ) : null}
                  {item.priceNight ? (
                    <Flex>
                      <Space w={30} />
                      <NameValue
                        name="Ночь"
                        fontSize="xs"
                        value={item.priceNight + ' ₽'}
                        valueColor="#4B5CFA"
                        nameWidth={170}
                      />
                    </Flex>
                  ) : null}
                </React.Fragment>
              ))
            : null}
          {object.sales?.length
            ? object.sales.map((item) => (
                <React.Fragment key={item.id}>
                  <NameValue name={`Скидка за ${item.days} дн.`} value={item.amount + ' ₽'} valueColor="#4B5CFA" />
                </React.Fragment>
              ))
            : null}
          <NameValue name="Время заезда" value={object.checkinTime} />
          <NameValue name="Время выезда" value={object.checkoutTime} />
          {object.dayCheckinTime ? <NameValue name="Время заезда (день)" value={object.dayCheckinTime} /> : null}
          {object.dayCheckoutTime ? <NameValue name="Время выезда (день)" value={object.dayCheckoutTime} /> : null}
          {object.nightCheckinTime ? <NameValue name="Время заезда (ночь)" value={object.nightCheckinTime} /> : null}
          {object.nightCheckoutTime ? <NameValue name="Время выезда (ночь)" value={object.nightCheckoutTime} /> : null}
          <NameValue name="Дата начала работы" value={dayjs(object.startWorkDate).format('DD.MM.YYYY')} />
          {/* <NameValue name="Недоступные даты" value={object.unavailableDates} /> */}
          <NameValue name="Город" value={object.city} />
          <NameValue name="Адрес" value={object.address} />
          <NameValue name="Страна" value={object.country} />
          <NameValue name="Широта" value={String(object.longitude)} />
          <NameValue name="Долгота" value={String(object.latitude)} />
          <NameValue name="Статус" value={status ? <Badge color={status.color}>{status.label}</Badge> : ''} />
          <NameValue name="Комментарий статуса" value={object.statusComment} />
          <NameValue
            name="Рейтинг"
            value={object.rating && object.reviewsCount ? object.rating / object.reviewsCount : ''}
          />
          <NameValue name="Количество отзывов" value={object.reviewsCount} isLast />
          <Space h="20px" />
          <Text>Пользователь</Text>
          <Space h="12px" />
          <Group>
            <Avatar w={100} h={100} src={`/uploads/${object.user.avatar}`} />
            <div>
              <NameValue name="" value={<Link to={`/users/${object.user.id}`}>{'ID ' + object.user.id}</Link>} />
              <NameValue name="" value={object.user.name} />
              <NameValue name="" value={object.user.phone} isLast />
            </div>
          </Group>
          <Space h="20px" />
          <Group>
            <Button variant="outline" size="xs" onClick={() => setEditStatusVisible(true)}>
              Изменить статус
            </Button>
            <Button
              onClick={() => {
                navigate(`/objects/${object.id}/update`);
              }}
              size="xs">
              Изменить объект
            </Button>
          </Group>
        </>
      ) : null}
      {object ? (
        <EditStatusModal
          visible={editStatusVisible}
          onClose={() => setEditStatusVisible(false)}
          defaultStatus={object.status}
          defaultStatusComment={object.statusComment}
          objectId={object.id}
        />
      ) : null}
    </>
  );
};

const EditStatusModal = ({ visible, onClose, defaultStatus, defaultStatusComment, objectId }) => {
  const [adminUpdateObject, { loading }] = useMutation(ADMIN_UPDATE_OBJECT, {
    onCompleted: () => {
      notifications.show({
        color: 'green',
        title: 'Статус объекта изменен',
      });
      onClose();
    },
    onError: (err) => {
      console.error(err);
      notifications.show({
        color: 'red',
        title: 'Ошибка',
        message: 'Произошла ошибка, повторите еще раз',
      });
    },
  });

  const [status, setStatus] = useState(defaultStatus || '');
  const [statusComment, setStatusComment] = useState(defaultStatusComment || '');

  const handleChangeStatus = () => {
    if (!status) return;
    adminUpdateObject({
      variables: {
        where: { id: objectId },
        data: {
          status,
          statusComment,
        },
      },
    });
  };

  return (
    <Modal opened={visible} onClose={onClose} title="Изменить статус">
      <Select
        label="Статус"
        data={[
          { value: 'new', label: 'На проверке' },
          { value: 'published', label: 'Размещен' },
          { value: 'cancelled', label: 'Не прошел проверку' },
          { value: 'archived', label: 'Архивирован' },
        ]}
        placeholder="Выберите статус"
        value={status}
        onChange={(v) => setStatus(v)}
      />
      <Space h="16px" />
      <Textarea label="Статус комментария" value={statusComment} onChange={(e) => setStatusComment(e.target.value)} />
      <Group justify="flex-end" mt={16}>
        <Button onClick={onClose} variant="outline">
          Отмена
        </Button>
        <Button loading={loading} onClick={handleChangeStatus}>
          Изменить
        </Button>
      </Group>
    </Modal>
  );
};

const Img = styled.img`
  width: 120px;
  height: 120px;
`;

const getDay = (day) => {
  if (day === 'monday') return 'Понедельник';
  if (day === 'tuesday') return 'Вторник';
  if (day === 'wednesday') return 'Среду';
  if (day === 'thursday') return 'Четверг';
  if (day === 'friday') return 'Пятницу';
  if (day === 'saturday') return 'Субботу';
  if (day === 'sunday') return 'Воскресенье';
  return '';
};
