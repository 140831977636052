import { gql } from "@apollo/client";

export const PUSH_NOTIFICATIONS = gql`
  query PUSH_NOTIFICATIONS($skip: Int, $take: Int) {
    pushNotifications(skip: $skip, take: $take) {
      id
      createdAt
      updatedAt
      title
      type
      userIds
    }
    pushNotificationsCount
  }
`;

export const CREATE_PUSH_NOTIFICATION = gql`
  mutation CREATE_PUSH_NOTIFICATION($data: CreatePushNotificationInput!) {
    createPushNotification(data: $data) {
      id
      createdAt
      updatedAt
      title
      type
      userIds
    }
  }
`;
