import {
  Button,
  Center,
  FileInput,
  Loader,
  Select,
  Space,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import React, { useState } from "react";
import styled from "styled-components";
import { DateInput } from "@mantine/dates";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";

import { DayPricesInput } from "../../components/day-prices-input";
import { SalesInput } from "../../components/sales-input";
import { ADMIN_UPDATE_OBJECT, OBJECT } from "../../gqls/object";

export const UpdateObjectPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [type, setType] = useState(undefined);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [square, setSquare] = useState(0);
  const [floor, setFloor] = useState(0);
  const [guestsCount, setGuestsCount] = useState(0);
  const [roomsCount, setRoomsCount] = useState(0);
  const [sleepCount, setSleepCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [price, setPrice] = useState(0);
  const [dayPrices, setDayPrices] = useState([]);
  const [sales, setSales] = useState([]);
  const [checkinTime, setCheckinTime] = useState(undefined);
  const [checkoutTime, setCheckoutTime] = useState(undefined);
  const [startWorkDate, setStartWorkDate] = useState(undefined);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("RU");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const [defaultImages, setDefaultImages] = useState([]);

  const [loading, setLoading] = useState(false);

  const [updateObject] = useMutation(ADMIN_UPDATE_OBJECT);

  const { loading: objectLoading } = useQuery(OBJECT, {
    variables: { where: { id: params.id } },
    onCompleted: (data) => {
      if (data?.object?.type) setType(data.object.type);
      if (data?.object?.title) setTitle(data.object.title);
      if (data?.object?.description) setDescription(data.object.description);
      if (data?.object?.square) setSquare(data.object.square);
      if (data?.object?.floor) setFloor(data.object.floor);
      if (data?.object?.guestsCount) setGuestsCount(data.object.guestsCount);
      if (data?.object?.roomsCount) setRoomsCount(data.object.roomsCount);
      if (data?.object?.sleepCount) setSleepCount(data.object.sleepCount);
      if (data?.object?.images?.length) setDefaultImages(data.object.images);
      if (data?.object?.price) setPrice(data.object.price);
      if (data?.object?.dayPrices?.length) {
        setDayPrices(
          data.object.dayPrices.map((item) => ({
            key: item.id,
            day: item.day,
            price: item.price,
          }))
        );
      }
      if (data?.object?.sales?.length) {
        setSales(
          data.object.sales.map((item) => ({
            key: item.id,
            days: item.days,
            amount: item.amount,
          }))
        );
      }
      if (data?.object?.checkinTime) setCheckinTime(data.object.checkinTime);
      if (data?.object?.checkoutTime) setCheckoutTime(data.object.checkoutTime);
      if (data?.object?.startWorkDate) {
        setStartWorkDate(dayjs(data.object.startWorkDate).toDate());
      }
      if (data?.object?.address) setAddress(data.object.address);
      if (data?.object?.city) setCity(data.object.city);
      if (data?.object?.country) setCountry(data.object.country);
      if (data?.object?.longitude) setLongitude(String(data.object.longitude));
      if (data?.object?.latitude) setLatitude(String(data.object.latitude));
    },
  });

  const handleUpdateObject = async () => {
    if (!files.length && !defaultImages.length) return;
    setLoading(true);
    let images = [];
    if (files.length) {
      const formData = new FormData();
      for (let file of files) {
        formData.append("files", file);
      }
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch("/api/admin/uploads", {
          method: "POST",
          body: formData,
          headers: { authorization: accessToken },
        });
        const json = await response.json();
        images = json?.filenames;
      } catch (err) {
        console.log(err);
        notifications.show({
          color: "red",
          title: "Ошибка",
          message: "Произошла ошибка, повторите еще раз",
        });
        setLoading(false);
        return;
      }
      if (!images.length) return;
    } else {
      images = defaultImages;
    }
    const data = {
      type,
      title,
      description,
      square,
      floor,
      guestsCount,
      roomsCount,
      sleepCount,
      images,
      price,
      dayPrices: dayPrices.map((item) => ({
        day: item.day,
        price: item.price,
      })),
      sales: sales.map((item) => ({ amount: item.amount, days: item.days })),
      checkinTime,
      checkoutTime,
      startWorkDate: dayjs(startWorkDate).toISOString(),
      address,
      city,
      country,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
    };
    updateObject({ variables: { where: { id: params.id }, data } })
      .then(() => {
        navigate(`/objects/${params.id}`);
        notifications.show({ color: "green", title: "Объект изменен" });
      })
      .catch((err) => {
        console.log(err);
        notifications.show({
          color: "red",
          title: "Ошибка",
          message: "Произошла ошибка, повторите еще раз",
        });
      })
      .finally(() => setLoading(false));
  };

  let buttonDisabled =
    !type ||
    !title ||
    !description ||
    !square ||
    !floor ||
    !guestsCount ||
    !roomsCount ||
    !price ||
    !checkinTime ||
    !checkoutTime ||
    !startWorkDate ||
    !address ||
    !city ||
    !country ||
    !longitude ||
    !latitude;

  if (!defaultImages.length && !files.length) {
    buttonDisabled = false;
  }

  if (objectLoading) {
    return (
      <Center pt={100} pb={100}>
        <Loader size="sm" />
      </Center>
    );
  }

  return (
    <>
      <Text>Изменить объект</Text>
      <Space h="12px" />
      <Form>
        <Select
          label="Тип"
          data={[
            { value: "flat", label: "Квартира" },
            { value: "studio", label: "Студия" },
            { value: "room", label: "Комната" },
            { value: "apartment", label: "Апартамент" },
            { value: "house", label: "Дом" },
            { value: "cottage", label: "Коттедж" },
            { value: "hostel", label: "Хостел" },
            { value: "hotel", label: "Гостиница" },
            { value: "guesthouse", label: "Гостевой дом" },
          ]}
          value={type}
          onChange={(v) => setType(v)}
        />
        <Space h={12} />
        <TextInput
          label="Заголовок"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Space h={12} />
        <Textarea
          label="Описание"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Space h={12} />
        <TextInput
          label="Площадь (м2)"
          value={square}
          type="number"
          onChange={(e) => setSquare(parseFloat(e.target.value))}
        />
        <Space h={12} />
        <TextInput
          label="Этаж"
          value={floor}
          type="number"
          onChange={(e) => setFloor(parseInt(e.target.value))}
        />
        <Space h={12} />
        <TextInput
          label="Количество гостей"
          value={guestsCount}
          type="number"
          onChange={(e) => setGuestsCount(parseInt(e.target.value))}
        />
        <Space h={12} />
        <TextInput
          label="Количество комнат"
          value={roomsCount}
          type="number"
          onChange={(e) => setRoomsCount(parseInt(e.target.value))}
        />
        <Space h={12} />
        <TextInput
          label="Количество спальных мест"
          value={sleepCount}
          type="number"
          onChange={(e) => setSleepCount(parseInt(e.target.value))}
        />
        <Space h={12} />
        <FileInput
          label="Изображения"
          multiple
          value={files}
          onChange={(v) => setFiles(v)}
        />
        {defaultImages?.length && !files.length ? (
          <>
            <Space h={12} />
            <Images>
              {defaultImages.map((item) => (
                <Image key={item} src={`/uploads/${item}`} />
              ))}
            </Images>
          </>
        ) : null}
        <Space h={12} />
        <TextInput
          label="Цена"
          value={price}
          type="number"
          onChange={(e) => setPrice(parseFloat(e.target.value))}
        />
        <Space h={12} />
        <DayPricesInput dayPrices={dayPrices} setDayPrices={setDayPrices} />
        <Space h={12} />
        <SalesInput sales={sales} setSales={setSales} />
        <Space h={12} />
        <Select
          label="Время заезда"
          value={checkinTime}
          onChange={(v) => setCheckinTime(v)}
          data={[...Array(24).keys()].map((item) => ({
            value: item > 9 ? `${item}:00` : `0${item}:00`,
            label: item > 9 ? `${item}:00` : `0${item}:00`,
          }))}
        />
        <Space h={12} />
        <Select
          label="Время выезда"
          value={checkoutTime}
          onChange={(v) => setCheckoutTime(v)}
          data={[...Array(24).keys()].map((item) => ({
            value: item > 9 ? `${item}:00` : `0${item}:00`,
            label: item > 9 ? `${item}:00` : `0${item}:00`,
          }))}
        />
        <Space h={12} />
        <DateInput
          label="Дата начала аренды"
          value={startWorkDate}
          onChange={(v) => setStartWorkDate(v)}
        />
        <Space h={12} />
        <TextInput
          label="Адрес"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Space h={12} />
        <TextInput
          label="Город"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <Space h={12} />
        <TextInput
          label="Страна"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          disabled
        />
        <Space h={12} />
        <TextInput
          label="Долгота"
          value={longitude}
          onChange={(e) => setLongitude(e.target.value)}
        />
        <Space h={12} />
        <TextInput
          label="Широта"
          value={latitude}
          onChange={(e) => setLatitude(e.target.value)}
        />
        <Space h={16} />
        <Button
          loading={loading}
          disabled={buttonDisabled}
          onClick={handleUpdateObject}
        >
          Изменить
        </Button>
      </Form>
    </>
  );
};

const Form = styled.div`
  max-width: 500px;
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -12px;
`;

const Image = styled.img`
  height: 100px;
  margin-right: 10px;
  margin-bottom: 12px;
`;
