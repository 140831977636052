import { useMutation, useQuery } from '@apollo/client';
import {
  ActionIcon,
  Button,
  Checkbox,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Space,
  Table,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconEdit } from '@tabler/icons-react';

import { CITY_CONFIGS, CREATE_CITY_CONFIG, UPDATE_CITY_CONFIG } from '../../gqls/config';
import { notifications } from '@mantine/notifications';

export const CityConfigs = () => {
  const { data, loading, refetch } = useQuery(CITY_CONFIGS);
  const cityConfigs = data?.cityConfigs || [];

  const [createCityConfig, { loading: createCityConfigLoading }] = useMutation(CREATE_CITY_CONFIG, {
    onCompleted: () => {
      refetch();
      setCreateCityConfigVisible(false);
      setCreateCity('');
    },
    onError: (err) => {
      notifications.show({
        color: 'red',
        title: 'Ошибка',
        message: err.message === 'already-exist' ? 'Уже существует город' : 'Произошла ошибка, повторите еще раз',
      });
    },
  });

  const [createCityConfigVisible, setCreateCityConfigVisible] = useState(false);
  const [createCity, setCreateCity] = useState('');

  const handleCreateCityConfig = () => {
    if (!createCity) return;
    createCityConfig({ variables: { data: { city: createCity } } });
  };

  const rows = cityConfigs.map((item) => (
    <Table.Tr key={item.id}>
      <Table.Td>{item.city}</Table.Td>
      <Table.Td>
        {item.holidayVerticalEnabled
          ? `Включен - ${item.holidayVerticalFirst ? 'Открывается первым' : 'Открывается потом'}`
          : 'Выключен'}
      </Table.Td>
      <Table.Td>{item.applicationAcceptAmount}</Table.Td>
      <Table.Td>
        <UpdateCityConfig item={item} />
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Flex justify="space-between">
        <Text mb={12}>Города</Text>
        <Button size="xs" variant="outline" onClick={() => setCreateCityConfigVisible(true)}>
          Добавить город
        </Button>
      </Flex>
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Город</Table.Th>
              <Table.Th>Вертикаль "Дома для отдыха"</Table.Th>
              <Table.Th>Сумма монет для принятия заявки на бронь</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Modal
        title="Создать настройки для города"
        opened={createCityConfigVisible}
        onClose={() => setCreateCityConfigVisible(false)}>
        <TextInput label="Город" value={createCity} onChange={(e) => setCreateCity(e.target.value)} />
        <Space h={20} />
        <Group justify="flex-end">
          <Button variant="outline" onClick={() => setCreateCityConfigVisible(false)}>
            Отмена
          </Button>
          <Button loading={createCityConfigLoading} onClick={handleCreateCityConfig}>
            Создать
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const UpdateCityConfig = ({ item }) => {
  const [opened, setOpened] = useState(false);

  const [holidayVerticalEnabled, setHolidayVerticalEnabled] = useState(false);
  const [holidayVerticalFirst, setHolidayVerticalFirst] = useState(false);
  const [applicationAcceptAmount, setApplicationAcceptAmount] = useState(0);

  useEffect(() => {
    setHolidayVerticalEnabled(item.holidayVerticalEnabled);
    setHolidayVerticalFirst(item.holidayVerticalFirst);
    setApplicationAcceptAmount(item.applicationAcceptAmount);
  }, [item.holidayVerticalEnabled, item.holidayVerticalFirst, item.applicationAcceptAmount]);

  const [updateCityConfig, { loading }] = useMutation(UPDATE_CITY_CONFIG, {
    onCompleted: () => {
      setOpened(false);
    },
    onError: (err) => {
      notifications.show({
        color: 'red',
        title: 'Ошибка',
        message: 'Произошла ошибка, повторите еще раз',
      });
    },
  });

  const handleUpdate = () => {
    updateCityConfig({
      variables: {
        where: { id: item.id },
        data: {
          holidayVerticalEnabled,
          holidayVerticalFirst,
          applicationAcceptAmount,
        },
      },
    });
  };

  return (
    <>
      <ActionIcon onClick={() => setOpened(true)} size="sm" variant="outline">
        <IconEdit style={{ width: '70%', height: '70%' }} />
      </ActionIcon>
      <Modal title={`Изменить настройки города ${item.city}`} opened={opened} onClose={() => setOpened(false)}>
        <Checkbox
          label='Включен вертикаль "Дома для отдыха"'
          checked={holidayVerticalEnabled}
          onChange={(e) => setHolidayVerticalEnabled(e.target.checked)}
        />
        <Space h={12} />
        <Checkbox
          label='"Дома для отдыха" открывается первым'
          checked={holidayVerticalFirst}
          onChange={(e) => setHolidayVerticalFirst(e.target.checked)}
        />
        <Space h={12} />
        <TextInput
          label="Сумма монет для принятия заявки на бронь"
          value={applicationAcceptAmount}
          onChange={(e) => setApplicationAcceptAmount(e.target.value ? parseFloat(e.target.value) : 0)}
        />
        <Space h={20} />
        <Group justify="flex-end">
          <Button variant="outline" onClick={() => setOpened(false)}>
            Отмена
          </Button>
          <Button loading={loading} onClick={handleUpdate}>
            Сохранить
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
