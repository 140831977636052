import { useMutation, useQuery } from '@apollo/client';
import { ActionIcon, Button, Group, LoadingOverlay, Modal, Space, Table, Text, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { IconEdit } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import { CONFIGS, UPDATE_CONFIG } from '../../gqls/config';
import { CityConfigs } from './city-configs';

export const ConfigsPage = () => {
  const { data, loading } = useQuery(CONFIGS);

  const [updatingConfig, setUpdatingConfig] = useState(null);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [value, setValue] = useState('');

  const configs = data?.configs || [];

  const [updateConfig, { loading: updateLoading }] = useMutation(UPDATE_CONFIG, {
    onCompleted: () => {
      closeModal();
      notifications.show({
        color: 'green',
        title: 'Успешно',
        message: 'Значение изменено',
      });
    },
    onError: (err) => {
      console.error(err);
      notifications.show({
        color: 'red',
        title: 'Ошибка',
        message: 'Произошла ошибка, повторите еще раз',
      });
    },
  });

  const closeModal = () => {
    setUpdateVisible(false);
    setTimeout(() => {
      setUpdatingConfig(null);
      setValue('');
    }, 300);
  };

  const handleUpdateConfig = () => {
    if (!updatingConfig || !value) return;
    updateConfig({
      variables: {
        where: { id: updatingConfig.id },
        data: { value },
      },
    });
  };

  const rows = configs.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>{item.label}</Table.Td>
        <Table.Td>{item.value}</Table.Td>
        <Table.Td>
          <ActionIcon
            onClick={() => {
              setUpdateVisible(true);
              setUpdatingConfig(item);
              setValue(item.value);
            }}
            size="sm"
            variant="outline">
            <IconEdit style={{ width: '70%', height: '70%' }} />
          </ActionIcon>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Text mb={12}>Настройки</Text>
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Название</Table.Th>
              <Table.Th>Значение</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Space h={30} />
      <CityConfigs />
      <Modal opened={updateVisible} onClose={closeModal} title="Изменить значение">
        <TextInput label="Значение" value={value} onChange={(e) => setValue(e.target.value)} />
        <Space h={20} />
        <Group justify="flex-end">
          <Button variant="outline" onClick={closeModal}>
            Отмена
          </Button>
          <Button loading={updateLoading} onClick={handleUpdateConfig}>
            Изменить
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
