import { useQuery } from "@apollo/client";

import { ADMIN } from "../gqls/admin";
import { useState } from "react";

export const useAdmin = () => {
  const [loading, setLoading] = useState(true);

  const { data } = useQuery(ADMIN, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (err) => {
      console.error(err);
      setLoading(false);
    },
  });

  const admin = data?.admin;

  return { admin, loading };
};
