import { useState } from "react";
import { Button, Flex, Space, Text, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { formatMaskedPhone, phoneMask, validateEmail } from "../../utils/masks";
import { ADMIN_CREATE_USER } from "../../gqls/user";

export const CreateUserPage = () => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [adminCreateUser, { loading }] = useMutation(ADMIN_CREATE_USER, {
    onCompleted: () => {
      notifications.show({
        color: "green",
        title: "Пользователь добавлен",
      });
      navigate("/users");
    },
    onError: (err) => {
      console.error(err);
      if (err.message === "already-exist") {
        notifications.show({
          color: "red",
          title: "Ошибка",
          message: "Пользователь с таким номером телефона уже существует",
        });
      } else {
        notifications.show({
          color: "red",
          title: "Ошибка",
          message: "Произошла ошибка, повторите еще раз",
        });
      }
    },
  });

  const handleAddUser = () => {
    if (!phone || !name || !email) return;
    if (!validateEmail(email)) {
      notifications.show({ color: "yellow", title: "Неправильный Email" });
      return;
    }
    const _phone = formatMaskedPhone(phone);
    if (_phone.length !== 12) {
      notifications.show({
        color: "yellow",
        title: "Неправильный номер телефона",
      });
      return;
    }
    adminCreateUser({
      variables: {
        data: {
          phone: _phone,
          name,
          email,
        },
      },
    });
  };

  return (
    <>
      <Text>Добавить пользователя</Text>
      <Space h="12px" />
      <Flex maw={300} direction="column">
        <TextInput
          label="Номер телефона"
          mb={12}
          value={phone}
          onChange={(e) => {
            phoneMask.resolve(e.target.value);
            setPhone(phoneMask.value);
          }}
        />
        <TextInput
          label="Имя"
          mb={12}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextInput
          label="Email"
          mb={12}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          onClick={handleAddUser}
          mt={12}
          disabled={!phone || !name || !email}
          loading={loading}
        >
          Добавить
        </Button>
      </Flex>
    </>
  );
};
