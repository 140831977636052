import {
  Avatar,
  Badge,
  Center,
  Loader,
  LoadingOverlay,
  Space,
  Table,
  Text,
} from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import styled from "styled-components";

import { SINGLE_USER } from "../../gqls/user";
import { NameValue } from "../../components/name-value";
import { OBJECTS } from "../../gqls/object";
import { getObjectStatus, getObjectType } from "../objects";

export const UserPage = () => {
  const params = useParams();

  const { data, loading } = useQuery(SINGLE_USER, {
    variables: { where: { id: params.id } },
  });
  const singleUser = data?.singleUser;

  const { data: objectsData, loading: objectsLoading } = useQuery(OBJECTS, {
    variables: { where: { userId: params.id } },
    skip: !singleUser,
  });
  const objects = objectsData?.objects || [];

  const objectsRows = objects.map((item) => {
    const status = getObjectStatus(item.status);
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <Link to={`/objects/${item.id}`}>{item.id.slice(0, 6)}...</Link>
        </Table.Td>
        <Table.Td>{getObjectType(item.type)}</Table.Td>
        <Table.Td>{item.title}</Table.Td>
        <Table.Td>{item.guestsCount}</Table.Td>
        <Table.Td>{item.roomsCount}</Table.Td>
        <Table.Td style={{ width: 80 }}>{item.price} ₽</Table.Td>
        <Table.Td>{item.city}</Table.Td>
        <Table.Td>{dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}</Table.Td>
        <Table.Td>
          <Badge color={status.color}>{status.label}</Badge>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Text>Пользователь {params.id}</Text>
      {loading ? (
        <Center>
          <Loader size="sm" mt={16} mb={16} />
        </Center>
      ) : singleUser ? (
        <>
          <Avatar
            src={
              singleUser.avatar ? `/uploads/${singleUser.avatar}` : undefined
            }
            mt={12}
            mb={16}
            size={80}
          />
          <NameValue name="Номер телефона" value={singleUser.phone} />
          <NameValue name="Имя" value={singleUser.name} />
          <NameValue name="Email" value={singleUser.email} />
          {singleUser.birthday ? (
            <NameValue
              name="Дата рождения"
              value={dayjs(singleUser.birthday).format("DD.MM.YYYY")}
            />
          ) : null}
          <NameValue
            name="Дата регистрации"
            value={dayjs(singleUser.createdAt).format("DD.MM.YYYY HH:mm")}
            isLast
          />
          <Space h="30px" />
          <Text>Объекты пользователя</Text>
          <TableView>
            <LoadingOverlay visible={objectsLoading} overlayBlur={2} />
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>ID</Table.Th>
                  <Table.Th>Тип</Table.Th>
                  <Table.Th>Название</Table.Th>
                  <Table.Th>Гостей</Table.Th>
                  <Table.Th>Комнат</Table.Th>
                  <Table.Th>Цена</Table.Th>
                  <Table.Th>Город</Table.Th>
                  <Table.Th>Дата создания</Table.Th>
                  <Table.Th>Статус</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{objectsRows}</Table.Tbody>
            </Table>
          </TableView>
        </>
      ) : null}
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1000px;
    width: 100%;
  }
`;
