import { gql } from '@apollo/client';

export const CONFIGS = gql`
  query CONFIGS {
    configs {
      id
      type
      label
      value
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation UPDATE_CONFIG($where: ConfigWhereInput!, $data: UpdateConfigInput!) {
    updateConfig(where: $where, data: $data) {
      id
      type
      label
      value
    }
  }
`;

const CITY_CONFIG_FRAGMENT = gql`
  fragment CityConfigReturn on CityConfig {
    id
    city
    holidayVerticalEnabled
    holidayVerticalFirst
    applicationAcceptAmount
  }
`;

export const CITY_CONFIGS = gql`
  ${CITY_CONFIG_FRAGMENT}
  query CITY_CONFIGS {
    cityConfigs {
      ...CityConfigReturn
    }
  }
`;

export const CREATE_CITY_CONFIG = gql`
  ${CITY_CONFIG_FRAGMENT}
  mutation CREATE_CITY_CONFIG($data: CreateCityConfig!) {
    createCityConfig(data: $data) {
      ...CityConfigReturn
    }
  }
`;

export const UPDATE_CITY_CONFIG = gql`
  ${CITY_CONFIG_FRAGMENT}
  mutation UPDATE_CITY_CONFIG($where: CityConfigWhereInput!, $data: UpdateCityConfig!) {
    updateCityConfig(where: $where, data: $data) {
      ...CityConfigReturn
    }
  }
`;
