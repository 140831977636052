import { useQuery } from "@apollo/client";
import { useState } from "react";
import {
  Badge,
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Pagination,
  Select,
  Table,
  Text,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

import { OBJECTS } from "../../gqls/object";

const TAKE = 30;

export const ObjectsPage = () => {
  const [activePage, setActivePage] = useState(1);

  const navigate = useNavigate();

  const [status, setStatus] = useState(undefined);

  const { data, loading } = useQuery(OBJECTS, {
    variables: {
      skip: (activePage - 1) * TAKE,
      take: TAKE,
      where: { status },
    },
    fetchPolicy: "network-only",
  });

  const objects = data?.objects || [];
  const objectsCount = data?.objectsCount || 0;

  const pagesCount = Math.ceil(objectsCount / TAKE);

  const rows = objects.map((item) => {
    const status = getObjectStatus(item.status);
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          <Link to={`/objects/${item.id}`}>{item.number}</Link>
        </Table.Td>
        <Table.Td>{getObjectType(item.type)}</Table.Td>
        <Table.Td>{item.title}</Table.Td>
        <Table.Td>{item.guestsCount}</Table.Td>
        <Table.Td>{item.roomsCount}</Table.Td>
        <Table.Td style={{ width: 80 }}>{item.price} ₽</Table.Td>
        <Table.Td>{item.city}</Table.Td>
        <Table.Td style={{ whiteSpace: "nowrap" }}>
          {dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}
        </Table.Td>
        <Table.Td>
          <Badge color={status.color}>{status.label}</Badge>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <Flex justify="space-between">
        <Text>Объекты ({objectsCount})</Text>
        <Button
          onClick={() => navigate("/objects/create")}
          variant="outline"
          size="xs"
        >
          Добавить объект
        </Button>
      </Flex>
      <Group mt={10} mb={12} spacing="sm">
        <Select
          size="xs"
          label="Статус"
          clearable
          data={[
            { value: "new", label: "На проверке" },
            { value: "published", label: "Размещен" },
            { value: "cancelled", label: "Не прошел проверку" },
            { value: "archived", label: "Архивирован" },
            { value: "deleted", label: "Удален" },
          ]}
          placeholder="Выберите статус"
          value={status}
          onChange={(v) => setStatus(v)}
        />
      </Group>
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>ID</Table.Th>
              <Table.Th>Тип</Table.Th>
              <Table.Th>Название</Table.Th>
              <Table.Th>Гостей</Table.Th>
              <Table.Th>Комнат</Table.Th>
              <Table.Th>Цена</Table.Th>
              <Table.Th>Город</Table.Th>
              <Table.Th>Дата создания</Table.Th>
              <Table.Th>Статус</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination
        onChange={(v) => setActivePage(v)}
        value={activePage}
        size="sm"
        mt={16}
        total={pagesCount}
      />
    </>
  );
};

export const getObjectType = (type) => {
  if (type === "flat") return "Квартира";
  if (type === "studio") return "Студия";
  if (type === "room") return "Комната";
  if (type === "apartment") return "Апартамент";
  if (type === "house") return "Дом";
  if (type === "cottage") return "Коттедж";
  if (type === "hostel") return "Хостел";
  if (type === "hotel") return "Гостиница";
  if (type === "guesthouse") return "Гостевой дом";
  return "";
};

export const getObjectStatus = (status) => {
  if (status === "new") return { label: "На проверке", color: "blue" };
  if (status === "published") return { label: "Размещен", color: "green" };
  if (status === "cancelled")
    return { label: "Не прошел проверку", color: "red" };
  if (status === "archived") return { label: "Архивирован", color: "gray" };
  if (status === "deleted") return { label: "Удален", color: "silver" };
  return { label: "", color: "" };
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
